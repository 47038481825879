import type { PagesProps } from '@graphcommerce/framer-next-pages'
import type { IfConfig, PluginProps } from '@graphcommerce/next-config'
import { GorgiasScript } from '../components/Gorgias/GorgiasScript'

export const component = 'FramerNextPages'
export const exported = '@graphcommerce/framer-next-pages'
export const ifConfig: IfConfig = 'gorgiasId'

function GorgiasFramerNextPages(props: PluginProps<PagesProps>) {
  const { Prev, ...rest } = props
  return (
    <>
      <GorgiasScript />
      <Prev {...rest} />
    </>
  )
}

export const Plugin = GorgiasFramerNextPages
