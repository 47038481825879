import Script from 'next/script'

export function GorgiasScript() {
  const gorgiasID = import.meta.graphCommerce.gorgiasId

  return (
    <Script
      id='gorgias-chat-widget-install-v3'
      src={`https://config.gorgias.chat/bundle-loader/${gorgiasID}`}
      strategy='afterInteractive'
    />
  )
}
